<template>
    <div class="row">
        <div class="col-sm-12 col-md-7">
            <div class="dataTables_paginate paging_simple_numbers">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" id="example2_previous">
                        <a @click="moveToPage(prePages)" class="page-link">上頁</a>
                    </li>

                    <li v-for="page in pages" :key="page" class="paginate_button page-item"
                        :class="{ 'active': page === this.CurrentPage }">

                        <a @click="moveToPage(page)" class="page-link">{{ page }}</a>
                    </li>

                    <li class="paginate_button page-item next" id="example2_next">
                        <a @click="moveToPage(nextPages)" class="page-link">下頁</a>
                    </li>

                </ul>
            </div>
        </div>
        <div class="col-sm-12 col-md-5">
            <div class="dataTables_info pull-right">符合筆數:{{ filterTotal }} </div>
            <div class="dataTables_info pull-right">總筆數:{{ total }} </div>
        </div>
    </div>

</template>
<script>
export default {
    name: 'Pagination',
    props: {
        total: {            // 總筆數
            required: true,
            type: Number,
            default: 0
        },
        filterTotal: {            // 篩選後符合筆數
            required: true,
            type: Number,
            default: 0
        },
        CurrentPage: {      // 當前頁數
            type: Number,
            default: 1
        },
        pageSize: {      // 每頁幾筆
            type: Number,
            default: 8
        },
    },
    data() {
        return {
            pages: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            totalPages: 0,
            beginPage: 1,
            isPageNumberError: false,
            pageSizes: [20, 50, 100],
            prePages: 1,
            nextPages: 1

        }
    },
    watch: {
        CurrentPage: function () {

            let size = Math.round(this.filterTotal / this.pageSize);
            let endSize = Math.min(10, size);

            this.beginPage = 1;
            if (this.CurrentPage > 5) {
                this.beginPage = Math.max(1, this.CurrentPage - 4);
            }

            //  alert(this.beginPage);
            
            if (this.beginPage > 5)
                endSize = Math.min(size, this.CurrentPage + 5);
            else endSize = Math.min(10, size);

            this.pages.length = 0;
            if (this.filterTotal > 0) {
                for (var i = this.beginPage; i <= endSize; i++) {
                    this.pages.push(i);
                }
            }

            this.prePages = Math.max(1, this.CurrentPage - this.pageSize);
            this.nextPages = Math.min(size, this.CurrentPage + this.pageSize);
        }
    },
    methods: {
        moveToPage(pageNum) {

            var pNum = parseInt(pageNum);

            //页数不合法则退出
            if (!pNum
                //    || pNum > this.totalPage 
                || pNum < 1) {
                console.log('頁碼輸入異常！');
                this.isPageNumberError = true;
                return false;
            } else {
                this.isPageNumberError = false;
            }


            //页数变化时的回调
            try {
                this.$emit('changeCallback', pNum);

            } catch (error) {
                console.log(error);
            }

        }
    }

}
</script>
