<template>
	<layout-div>
		<div class="card">
			<div class="card-header">
				<div class="btn-group">
					<button @click="showfilterForm()" class="btn btn-secondary">搜尋</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
										</a>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.id">
									<td>{{item.customer}}</td>
									<td>{{item.housecode}}</td>
									<td>{{item.inverter_id}}</td>
									<td>{{item.name}}</td>
									<td>{{item.R_ACv}}</td>
									<td>{{item.R_ACi}}</td>
									<td>{{item.PV1DCv}}</td>
									<td>{{item.PV1DCi}}</td>
									<td>{{item.current_pw}}</td>
									<td>{{item.day_pw}}</td>
									<td>{{item.boardspower}}</td>
									<td>{{item.effic}}</td>
									<td>{{item.sum_pw}}</td>
									<td>{{item.avg_pw}}</td>
									<td>{{item.valuation}}</td>
									<td>{{item.status}}</td>
									<td>{{item.dt1}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total=this.total :filterTotal=this.filterTotal :CurrentPage=this.pagination.page
					@changeCallback="onPageChange" />

			</div>
		</div>
	</layout-div>
	<div class="modal fade" id="filterModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">搜尋</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="card">
						<form asp-action="Index" id="searchForm">
							<div class="card-body row">
					<div class="col-auto">
						<label class="control-label">客戶</label>
						<input v-model="filter.customer" id="customer" class="form-control" />
					</div>
					<div class="col-auto">
						<label class="control-label">案場代號</label>
						<input v-model="filter.housecode" id="housecode" class="form-control" />
					</div>

							</div>
						</form>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" @click="reset()" class="btn btn-primary">清除</button>
					<button type="button" @click="fetchList()" class="btn btn-primary">開始搜尋</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>

<script>
import $ from 'jquery'
import baseList from '@/views/baseList.vue'
import { listPowerDayend } from '@/api/Power.js'


export default {
	name: 'PowerDayendlist',
	extends: baseList,
	data() {
		return {
			filter: {
					customer: "",
					housecode: "",

			},
			pagination: {
				sortItem: "id",
				sortDir: "ASC",
				page: 1,
				pageSize: 10
			},
			headers: [
				{id: 'customer', label: "客戶", icon: ""},
				{id: 'housecode', label: "案場代號", icon: ""},
				{id: 'inverter_id', label: "逆變器位址", icon: ""},
				{id: 'name', label: "逆變器", icon: ""},
				{id: 'R_ACv', label: "R交流電壓", icon: ""},
				{id: 'R_ACi', label: "R交流電流", icon: ""},
				{id: 'PV1DCv', label: "PV1直流電壓", icon: ""},
				{id: 'PV1DCi', label: "PV1直流電流", icon: ""},
				{id: 'current_pw', label: "目前輸出功率(W) ", icon: ""},
				{id: 'day_pw', label: "當日發電度數(KW/H)", icon: ""},
				{id: 'boardspower', label: "額定電量(KW)", icon: ""},
				{id: 'effic', label: "每K效率", icon: ""},
				{id: 'sum_pw', label: "累積發電度數(KW/H)", icon: ""},
				{id: 'avg_pw', label: "平均每日發電數", icon: ""},
				{id: 'valuation', label: "估值", icon: ""},
				{id: 'status', label: "逆變器狀態", icon: ""},
				{id: 'dt1', label: "記錄時間", icon: ""},

			],
			model: [],
			total: 0,
			filterTotal: 0

		};
	},
	methods: {
		reset() {
			this.filter.customer = "";
			this.filter.housecode = "";

		},
		getRequest() {
			var result =
			{
				"filter": this.filter,
				"pagination": this.pagination
			};

			return result;
		},
		fetchList() {

			listPowerDayend(this.getRequest()).then(rep => {

				let sc = rep.data;
				this.total = sc.total;
				this.filterTotal = sc.filterTotal;
				this.model = sc.items;
			});
		},

		showfilterForm() {
			$("#filterModal").modal('show');
		}
	},
};
</script>
